import Button from '@components/atoms/Button';
import {GlobalContainer} from '@styles/pages/_app.styles';
import Image from 'next/legacy/image';
import {useRouter} from 'next/router';
import {
  Buttons,
  Heading,
  ImageWrapper,
  Message,
  MessageWrapper,
  Text,
} from './ErrorPage.styles';

interface ErrorPageProps {
  error?: 404 | 500;
}

const ErrorPage = ({error = 404}: ErrorPageProps) => {
  const router = useRouter();

  let image, heading, text;
  switch (error) {
    case 500:
      image = '/assets/Error500.svg';
      heading = 'Запрос не может быть обработан';
      text = 'Попробуйте обновить страницу или зайти позже';
      break;
    case 404:
      image = '/assets/Error404.svg';
      heading = 'Извините, страница, которую вы ищете, сейчас недоступна';
      text = 'Возможно, она переехала по другому адресу или больше не существует';
  }

  return (
    <GlobalContainer>
      <MessageWrapper>
        <Message>
          <ImageWrapper>
            <Image src={image} layout="fill" alt="image" />
          </ImageWrapper>
          <Heading>{heading}</Heading>
          <Text>{text}</Text>
          <Buttons>
            <Button
              title="Вернуться на главную"
              style={{padding: '15px 40px'}}
              fontSize={16}
              variant="filled"
              link="/"
            />
            <Button
              title="Перейти в каталог"
              style={{padding: '15px 40px'}}
              fontSize={16}
              link="/catalog/"
            />
          </Buttons>
        </Message>
      </MessageWrapper>
    </GlobalContainer>
  );
};

export default ErrorPage;
