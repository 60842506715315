import {theme} from '@styles/theme';
import styled from 'styled-components';

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 92px 0;
  @media (max-width: 768px) {
    margin: 47px 0;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 660px;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  width: 564px;
  height: 256px;
  position: relative;
  @media (max-width: 1100px) {
    width: 382px;
    height: 191px;
  }
  @media (max-width: 768px) {
    width: 256px;
    height: 128px;
  }
`;

export const Heading = styled.h2`
  margin-top: 40px;
  font-family: ${theme.fonts.headline};
  font-size: 36px;
  color: ${theme.colors.brandGreenV2};
  @media (max-width: 1100px) {
    margin-top: 33px;
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Text = styled.div`
  margin-top: 32px;
  font-size: 18px;
  color: ${theme.colors.darkText};
  @media (max-width: 1100px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    margin-top: 16px;
    font-size: 15px;
  }
`;

export const Buttons = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 15px;
  @media (max-width: 768px) {
    margin-top: 40px;
    flex-direction: column;
    * {
      width: 100%;
    }
  }
`;
