import type {NextPage} from 'next';
import ErrorPage from '@components/organisms/ErrorPage';
import {useEffect} from 'react';
import ym from 'react-yandex-metrika';

const Error404Page: NextPage = () => {
  useEffect(() => {
    const ref = document.referrer;
    const url = document.location.href;
    const params404 = {
      '404 ошибка': {
        'URL-адрес': url,
        Реферер: ref,
      },
    };
    ym('reachGoal', '404error', {params: params404});
  }, []);

  return <ErrorPage error={404} />;
};

export default Error404Page;
